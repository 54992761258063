import React from 'react'

function Buy() {
  


  return (
    <>
        <iframe  style={{    border:' 2px solid #21273a' , borderRadius : '15px' ,  width: '490px' , height: '590px'}}
        allow="usb; ethereum; clipboard-write" loading="lazy" src="https://global.transak.com/" ></iframe>

  </>
  
  
  )
}

export default Buy